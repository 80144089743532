import {HttpInterceptorFn, HttpParams} from '@angular/common/http';
import {inject} from '@angular/core';
import {AuthService} from './modules/shared/services/auth.service';
import {environment} from '../environments/environment';
import {ParamEnum} from './modules/shared/enums/param.enum';

export const httpRequestInterceptor: HttpInterceptorFn = (request, next) => {
  const apiUrls = [environment.ibricksApiV2, environment.ibricksApiV3, environment.upload, environment.imageUpload];

  if (apiUrls.some(i => request.url.includes(i))) {
    const anonymousUse = request.params.get(ParamEnum.AnonymousUse) === '1';
    const orgParam = request.params.get(ParamEnum.Org) || '';

    const getParams = (): HttpParams => {
      if (!orgParam) {
        const org = inject(AuthService).org;
        if (org) {
          return request.params.set(ParamEnum.Org, org);
        }
      }
      return request.params;
    };

    request = request.clone({
      withCredentials: anonymousUse ? false : environment.production,
      params: getParams()
    });

    // Basic auth commented because keycloak token should be valid for api-dev.ibricks.no/v3
    // if (!environment.production && environment.username && environment.password) {
    //   request = request.clone({
    //     headers: request.headers.append(
    //       'Authorization',
    //       'Basic ' + btoa(`${environment.username}:${environment.password}`)
    //     )
    //   });
    // }
  }

  return next(request);
};
