import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterOutlet} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AppService} from './app.service';
import {LayoutService} from './modules/shared/services/layout.service';
import {OfflineService} from './modules/shared/services/offline.service';
import {SimpleToolbarComponent} from './modules/shared/layout/simple-toolbar/simple-toolbar.component';
import {isUiLessTemplate} from './modules/shared/utilities';

@Component({
  selector: 'app-root',
  standalone: true,
  template: `
    <ng-container *ngIf="isSpinnerVisible">
      <div class="loading-spinner"></div>
      <div class="loading-info">Please wait while iBricks is loading...</div>
    </ng-container>
    <ng-template #container></ng-template>
    <app-simple-toolbar *ngIf="!isSidenavVisible && isUiLess"></app-simple-toolbar>
    <router-outlet *ngIf="!isSidenavVisible"></router-outlet>
  `,
  imports: [CommonModule, RouterOutlet, SimpleToolbarComponent]
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;
  private readonly destroyer = new Subject<void>();
  public isUiLess?: boolean;
  public isSidenavVisible?: boolean;
  public isSpinnerVisible = true;

  constructor(
    private layoutService: LayoutService,
    private changeDetector: ChangeDetectorRef,
    private appService: AppService,
    private offlineService: OfflineService
  ) {}

  ngOnInit(): void {
    this.subscribeRouter();
    this.appService.subscribeSwUpdate();
    this.appService.subscribeLogsEvent();
  }

  ngOnDestroy(): void {
    this.destroyer.next();
    this.destroyer.complete();
  }

  private subscribeRouter(): void {
    this.layoutService
      .getNavigationEnd()
      .pipe(takeUntil(this.destroyer))
      .subscribe(e => {
        this.appService.setTheme(e.url); // set theme each time when route changes
        if (this.appService.isAuthenticated && !this.container?.length) {
          this.initSidenav();
          // TODO IndexedDb integration
          // this.getOfflineDataAndSubscribeAutoSync();
          // this.appService.connectWebSocket();
        } else {
          this.isSpinnerVisible = false;
          this.isUiLess = isUiLessTemplate(e.url);
        }
      });
  }

  private getOfflineDataAndSubscribeAutoSync(): void {
    this.offlineService
      .getOfflineData()
      .subscribe(() => this.offlineService.autoSync().pipe(takeUntil(this.destroyer)).subscribe());
  }

  /**
   * Sets true into {@link isSidenavVisible} variable to remove router outlet from template.
   * Router outlet should be removed from DOM before component creation to prevent double router outlet in template.
   * @private
   */
  private initSidenav(): void {
    this.isSpinnerVisible = false;
    this.isSidenavVisible = true;
    this.changeDetector.detectChanges();
    this.appService.initSidenav(this.container);
  }
}
