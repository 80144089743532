import {Routes} from '@angular/router';
import {authGuard} from './modules/shared/guards/auth.guard';
import {RouteEnum} from './modules/shared/enums/route.enum';
import {ParamEnum} from './modules/shared/enums/param.enum';

export const routes: Routes = [
  {
    path: '*',
    redirectTo: RouteEnum.Dashboard
  },
  {
    path: '',
    redirectTo: RouteEnum.Dashboard,
    pathMatch: 'full'
  },
  {
    path: RouteEnum.Dashboard,
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [authGuard]
  },
  // {
  //   path: RouteEnum.Auth,
  //   loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  // },
  {
    path: RouteEnum.Search,
    loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule)
  },
  {
    path: RouteEnum.Help,
    loadChildren: () => import('./modules/help/help.module').then(m => m.HelpModule)
  },
  {
    path: RouteEnum.Profiles,
    loadChildren: () => import('./modules/profiles/profiles.module').then(m => m.ProfilesModule)
  },
  {
    path: RouteEnum.Contacts,
    loadChildren: () => import('./modules/contacts/contacts.module').then(m => m.ContactsModule)
  },
  {
    path: RouteEnum.Companies,
    loadChildren: () => import('./modules/companies/companies.module').then(m => m.CompaniesModule)
  },
  {
    path: RouteEnum.Projects,
    loadChildren: () => import('./modules/projects/projects.module').then(m => m.ProjectsModule)
  },
  {
    path: RouteEnum.QaDocuments,
    loadChildren: () => import('./modules/quality/quality.module').then(m => m.QualityModule),
    canActivate: [authGuard]
  },
  {
    path: RouteEnum.Incidents,
    loadChildren: () => import('./modules/incidents/incidents.module').then(m => m.IncidentsModule)
  },
  {
    path: RouteEnum.Forms,
    loadChildren: () => import('./modules/schemas/schemas.module').then(m => m.SchemasModule)
  },
  {
    path: RouteEnum.Organization,
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [authGuard]
  },
  {
    path: RouteEnum.Tasks,
    loadChildren: () => import('./modules/tasks/tasks.module').then(m => m.TasksModule),
    canActivate: [authGuard]
  },
  {
    path: RouteEnum.Documents,
    loadChildren: () => import('./modules/documents/documents.module').then(m => m.DocumentsModule),
    canActivate: [authGuard]
  },
  {
    path: RouteEnum.Web,
    loadChildren: () => import('./modules/web/web.module').then(m => m.WebModule),
    canActivate: [authGuard]
  },
  {
    path: RouteEnum.Employees,
    loadChildren: () => import('./modules/employees/employees.module').then(m => m.EmployeesModule),
    canActivate: [authGuard]
  },
  {
    path: RouteEnum.Correspondence,
    loadChildren: () => import('./modules/correspondence/correspondence.module').then(m => m.CorrespondenceModule),
    canActivate: [authGuard]
  },
  {
    // page where anonymous users may update newsletter settings
    path: `p/:${ParamEnum.Id}`,
    redirectTo: `${RouteEnum.Profiles}/${RouteEnum.NewsletterSettings}/:${ParamEnum.Id}` // profile.userUnid
  },
  {
    // Nordveggen Jobs Module
    path: RouteEnum.Rauma,
    loadChildren: () => import('jobs-in-rauma').then(m => m.JobsInRaumaModule),
    canActivate: [authGuard]
  },
  {path: '**', redirectTo: RouteEnum.Dashboard}
];
